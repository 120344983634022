/*
* 업무구분: 이벤트 &gt; 한글자 운세
* 화면 명: MSPSA070M
* 화면 설명: 한글자 운세 메인 화면
*/
<template>
  <ur-page-container title="한글자 운세" :show-title="true" type="subpage">
    <!-- 한글자운세 전체 S -->
    <ur-box-container direction="column" align-v="start" align-h="center" componentid=""  class="nw-qw-wrap nw-unse-wrap">
      <!-- 한글자운세 메인 S -->
      <ur-box-container direction="column" align-v="start" align-h="center" class="nw-qw-main nw-unse-main">
        <span class="unse-stit"><b>한</b> 글자로 보는</span>
        <strong class="unse-tit">갑진년 운세</strong>
        <span class="unse-txt">2024년 희망찬 새해 운세를 읽어보시고,<br> 술술 풀리는 한 해 되세요!</span>
        <mo-button class="btn-start" @click="fn_Start">
          시작하기
          <i class="ic-more"/>
        </mo-button>        
      </ur-box-container>
      <!-- 한글자운세 메인 E -->
    </ur-box-container>
    <!-- 한글자운세 전체 E -->
  </ur-page-container>
</template>
<script>
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA070M",
    screenId: "MSPSA070M",
    components: {
    },
    // inject: ['$validator'],
    props: {
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    beforeDestroy() {},
    /************************************************************************************************
     * 화면 전역 변수 선언 영역
     ************************************************************************************************/
    data() {
      return {}
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    watch: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_Start() {
        this.$router.push( { name: 'MSPSA071M' } )
      }
    }
  };
</script>
<style scoped>
.ur-box-container.nw-unse-wrap {
  padding: 0;
}
</style>